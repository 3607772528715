<template>
  <div>
    <input :ref="`date-range-${_uid}`" size="40" :value="dateRange" class="d-none"/>
    <div :id="`date-range-container-${_uid}`" style="width:100%;"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import $ from 'jquery';
import 'moment/dist/moment';
import 'jquery-date-range-picker';
import dayjs from 'dayjs';

export default {
  name: 'date-range-control',
  props: ['dateRange'],
  model: {
    prop: 'dateRange',
    event: 'change',
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  data() {
    return {
      dateJQueryObject: null,
    };
  },
  mounted() {
    const vm = this;
    const today = dayjs(new Date()).format('YYYY-MM-DD');
    $.dateRangePickerLanguages.custom = {
      'week-1': this.$t('weekShortName.mon'),
      'week-2': this.$t('weekShortName.tue'),
      'week-3': this.$t('weekShortName.wed'),
      'week-4': this.$t('weekShortName.thu'),
      'week-5': this.$t('weekShortName.fri'),
      'week-6': this.$t('weekShortName.sat'),
      'week-7': this.$t('weekShortName.sun'),
      'month-name': Object.values(this.$t('monthName')),
    };

    this.dateJQueryObject = $(this.$refs[`date-range-${this._uid}`]);
    this.dateJQueryObject.dateRangePicker({
      inline: true,
      alwaysOpen: true,
      language: 'custom',
      showTopbar: false,
      stickyMonths: true,
      singleMonth: false,
      format: 'YYYY-MM-DD',
      startDate: today,
      container: `#date-range-container-${this._uid}`,
    }).bind('datepicker-change', (evt, obj) => {
      const a = dayjs(obj.date1).format('YYYY-MM-DD');
      const b = dayjs(obj.date2).format('YYYY-MM-DD');
      vm.$emit('change', [a, b]);
    });
  },
  methods: {
    clear() {
      this.dateJQueryObject.data('dateRangePicker').clear();
    },
  },
};
</script>

<style>
  .date-picker-wrapper.show {
    display: block !important;
  }
  .date-picker-wrapper .month-wrapper table .day.invalid {
    opacity: 0.5;
  }
  .date-picker-wrapper .gap {
    height: 0 !important;
  }
</style>
